.quill {
  display: flex;
  flex-direction: column;
}
.quill .ql-container {
  flex: 1;
  display: flex;
}
.quill .ql-container .ql-editor {
  flex: 1;
  height: auto;
}
